import React from 'react'

function Zero() {
    return (
        <div className='h-screen w-screen overflow-auto bg-gradient-to-b from-zinc-400 to-slate-300'>
            <div className='max-w-screen-lg mx-auto flex flex-col px-4'>
                <div className='flex flex-col h-full mt-24 items-center justify-evenly py-6'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black'>
                        Chat with Zero
                    </h2>
                    <p className='py-6 text-center text-xl md:text-3xl text-black'>Zero is a chatbot that can answer some FAQs about my resume. </p>
                    <p className='py-6 text-center text-xl md:text-3xl text-black'>Unfortunately,
                    Zero has been retired to save me money 😔.</p>
                </div>
                <div className='flex justify-center items-center mb-10'>
                    {/* <iframe
                        title='Zero'
                        allow="microphone;"
                        width="350"
                        height="430"
                        src="https://console.dialogflow.com/api-client/demo/embedded/84ce20e6-80ef-4da1-8be8-e5a9d84cc886">
                        className='mb-10'
                    </iframe> */}
                </div>
            </div>
        </div>
    )
}

export default Zero