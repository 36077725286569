function Skills() {

    const langs = [
        {
            id: 1,
            title: 'Python',
            style: 'shadow-sky-500',
            description: '5+ years of experience, ranging from AI models to web development using Flask'

        },
        {
            id: 2,
            title: 'Javascript',
            style: 'shadow-yellow-500',
            description: '3+ years of experience, web development using React.js and Node.js'

        },
        {
            id: 3,
            title: 'Java',
            style: 'shadow-teal-500',
            description: '3+ years of experience, college coursework and course projects'

        },
        {
            id: 7,
            title: 'Typescript',
            style: 'shadow-blue-600',
        },
        {
            id: 4,
            title: 'C++',
            style: 'shadow-blue-600',
            description: '1+ years of experience, college coursework and course projects'

        },
        {
            id: 5,
            title: 'SQL (MySQL, Postgres)',
            style: 'shadow-teal-600',
            description: '3+ years of experience, MySQL, Sqlite, MSSQL through coursework and internships'

        },
        {
            id: 6,
            title: 'Go',
            style: 'shadow-blue-600',
            description: '1+ years of experience, personal projects and internships'
        },
        {
            id: 8,
            title: 'C',
            style: 'shadow-blue-600',

        },
        {
            id: 9,
            title: 'HTML/CSS',
            style: 'shadow-orange-600',
        }

    ]

    const tools = [
        // {
        //     id: 1,
        //     title: 'Microsoft Azure',
        //     style: 'shadow-blue-700',
        //     description: '1+ years of experience, multiple internships and currently training for a certification'
        // },
        {
            id: 2,
            title: 'AWS',
            style: 'shadow-gray-700',
            description: '1+ years of experience, personal projects, bootcamp training, and certifications'
        },
        {
            id: 3,
            title: 'Google Cloud Platform',
            style: 'shadow-red-700',
            description: '~1 year of experience, personal projects and bootcamp training'
        },

        {
            id: 5,
            title: 'Docker',
            style: 'shadow-teal-700',
            description: '1+ years of experience, coursework, projects, and internship'
        },
        {
            id: 7,
            title: 'Kubernetes',
            style: 'shadow-blue-700',
            description: '1+ years of experience'
        },
        {
            id: 8,
            title: 'Terraform',
            style: 'shadow-purple-700',
            description: 'beginner level, experience through bootcamp training'
        },
        {
            id: 9,
            title: 'VSCode and Git',
            style: 'shadow-blue-600',
            description: '5+ years of experience, used in almost every piece of software written'
        },
        {
            id: 10,
            title: 'Ansible',
            style: 'shadow-red-600',
            description: '1+ years of experience, used for testing APIs in personal projects and internships'
        }
    ]

    const frameworks = [
        {
            id: 1,
            title: 'React.js',
            style: 'shadow-sky-500',

        },
        {
            id: 2,
            title: 'Next.js',
            style: 'shadow-gray-900',
            description: '1 year of experience, University of Alberta specialization and internship'

        },
        {
            id: 3,
            title: 'Flask',
            style: 'shadow-teal-500',
            description: '1+ years of experience, University of Alberta specialization and internships'

        },
        {
            id: 4,
            title: 'NodeJS',
            style: 'shadow-green-500',
            description: '2+ years of experience, personal project and internship'

        },
        {
            id: 5,
            title: 'Langchain',
            style: 'shadow-amber-700',
            description: 'beginner level, training course offered by Atlassian'

        },
        {
            id: 6,
            title: 'TailwindCSS',
            style: 'shadow-teal-600',
            description: '2+ years of experience, primary choice of styling for web apps'

        },
        {
            id: 6,
            title: 'FastAPI',
            style: 'shadow-blue-600',
            description: '2+ years of experience, primary choice of styling for web apps'

        },

    ]



    return (
        <div className='h-screen w-screen overflow-auto bg-gradient-to-b from-zinc-400 to-slate-300'>
            <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center px-4'>
                <div className='flex flex-col h-full mt-24 items-center justify-evenly'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black'>
                        Skills
                    </h2>
                </div>
                <div className="text-4xl font-bold border-b-4 border-gray-900 text-black p-2 inline mt-32 md:mt-16">
                    <p>
                        Programming Languages
                    </p>
                </div>

                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 md:mt-0">

                    {langs.map(lang => (
                        <div
                            key={lang.id}
                            className={`bg-slate-800 shadow-lg hover:scale-110 duration-500 py-2 rounded-lg ${lang.style}`}>

                            <h3 className="text-2xl text-white font-semibold">{lang.title}</h3>
                            {/* <p className="mt-4 text-gray-300">Relevant Experience:</p>
                            <p className="text-white">{lang.description}</p> */}

                        </div>
                    ))}

                </div>

                <div className="text-4xl font-bold border-b-4 border-gray-900 text-black p-2 inline mt-32 md:mt-20">
                    <p>
                        Tools and Technologies
                    </p>
                </div>

                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 md:mt-0">

                    {tools.map(tool => (
                        <div
                            key={tool.id}
                            className={`bg-slate-800 shadow-lg hover:scale-110 duration-500 py-2 rounded-lg ${tool.style}`}>

                            <h3 className="text-2xl text-white font-semibold">{tool.title}</h3>
                            {/* <p className="mt-4 text-gray-300">Relevant Experience:</p> */}
                            {/* <p className="text-white">{tool.description}</p> */}

                        </div>
                    ))}

                </div>
                <div className="text-4xl font-bold border-b-4 border-gray-900 text-black p-2 inline mt-32 md:mt-20">
                    <p>
                        Frameworks
                    </p>
                </div>

                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 md:mt-0">

                    {frameworks.map(pmskill => (
                        <div
                            key={pmskill.id}
                            className={`bg-slate-800 shadow-lg hover:scale-110 duration-500 py-2 rounded-lg ${pmskill.style}`}>

                            <h3 className="text-2xl text-white font-semibold">{pmskill.title}</h3>
                            {/* <p className="mt-4 text-gray-300">Relevant Experience:</p>
                            <p className="text-white">{pmskill.description}</p> */}

                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

export default Skills;