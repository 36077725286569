import React from "react";

function ComingSoon() {
    return (
        <>
            <div className='h-screen w-full bg-gradient-to-b from-black to-gray-800'>
                <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
                    <div className='flex flex-col justify-center h-full'>
                        <h2 className='text-4xl md:text-7xl font-bold text-white'>
                            Coming soon 😎
                        </h2>
                        <p className='text-2xl md:text-3xl text-gray-500 py-4 max-w-md'>
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ComingSoon;