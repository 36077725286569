import React, { useState } from 'react'
import { FaBars, FaTimes } from "react-icons/fa"
import { Link } from 'react-router-dom';

function Navbar() {

    const [nav, setNav] = useState(false);
    const [active, setActive] = useState(1);

    const links = [
        {
            id: 1,
            name: 'home',
            link: '/'
        },
        {
            id: 2,
            name: 'Zero',
            link: '/zero'
        },
        {
            id: 3,
            name: 'projects',
            link: '/projects'
        },
        {
            id: 4,
            name: 'experience',
            link: '/experience'
        },
        {
            id: 5,
            name: 'skills',
            link: '/skills'
        },
        {
            id: 6,
            name: 'contact me',
            link: '/contactme'
        },
    ];

    return (
        <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-gradient-to-r from-zinc-800 via-black to-zinc-800 fixed">
        <Link onClick={()=> setActive(1)} to={'/'}>
            <div>
                <p className='px-4 cursor-pointer capitalize font-medium text-4xl hover:scale-125 duration-150'>Aditya</p>
            </div>
        </Link>

            <ul className='hidden md:flex'>
                {links.map(link_item => (
                    <li key={link_item.id}
                        className='px-4 cursor-pointer font-medium text-gray-400 hover:scale-125 duration-150'>
                        <Link to={link_item.link}>
                            <button onClick={()=> setActive(link_item.id)} className={`capitalize border-b-4 ${(active === link_item.id)? `border-slate-300`:`border-gray-600`}`}>
                                {link_item.name}
                            </button>
                        </Link>
                    </li>
                ))}
            </ul>

            <div onClick={() => setNav(!nav)} className='cursor-pointer pr-4 z-10 text-gray-500 hover:scale-105 duration-200 md:hidden'>
                {nav ? <FaTimes size={40} /> : <FaBars size={40} />}
            </div>

            {nav &&
                <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-gray-400 md:hidden'>
                    {links.map(link_item => (
                        <li key={link_item.id}
                            className='px-4 cursor-pointer capitalize py-6 text-4xl hover:scale-125 duration-150'>
                            <Link to={link_item.link}>
                                <button onClick={() => setNav(!nav)} className='capitalize border-b-4 border-gray-600'>
                                    {link_item.name}
                                </button>
                            </Link>
                        </li>
                    ))}
                </ul>
            }
        </div>
    )
}

export default Navbar