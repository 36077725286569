import React from 'react'

function Experience() {
    return (
        <div className='h-screen w-screen overflow-auto bg-gradient-to-b from-zinc-400 to-slate-300'>
            <div className='max-w-screen-lg mx-auto flex flex-col px-4'>
                <div className='flex flex-col h-full mt-24 items-center justify-evenly'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black'>
                        Experience
                    </h2>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-teal-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Cloud infrastructure Engineer
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Network Science Institute • Boston, Massachusetts
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        07/2024 - 12/2024
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">

                        <li>
                            Engineered an IaC GitLab pipeline utilizing Bash and Python to standardize VM template creation, resulting in
                            a 95% reduction in developer setup time and a 50% faster deployment rate.                    </li>
                        <li>
                            Deployed a distributed time-series database with Ansible to store TCP/flow traffic, creating a scalable system for
                            real-time monitoring and enabling efficient network traffic analysis.
                        </li>
                        <li>
                            Overhauled a HA Kubernetes cluster using Ansible with IPVS and Keepalived, improving failover and load
                            balancing by expanding health check metrics by 4x, achieving 98% uptime.

                        </li>
                        <li>
                            Bootstrapped multiple distributed storage backends, such as GlusterFS and block devices, to test for
                            throughput and HA before deploying across the entire cluster.
                        </li>
                        <li>
                            Programmed Watchdog, a multithreaded monitoring and alerting service built in Golang, using
                            Prometheus, Amazon SNS, and AWS Lambda to enhance fault tolerance for in-cluster operations.
                        </li>
                        <li>
                            Deployed a CI/CD pipeline leveraging CloudFormation for infrastructure automation, GitLab Runner
                            for builds, CodeDeploy for deploying services on EC2, and Systems Manager for automatic updates.
                        </li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-teal-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Software Engineer - Product and Full Stack
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Northeastern Electric Racing • Boston, Massachusetts
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        01/2024 - 12/2024
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">

                        <li>
                            Driving feature ideation and design within the product team, while implementing these features using TypeScript, React, and Express.js as part of the software team.                     </li>
                        <li>
                            Developed new backend features for Finishline: an in-house project management dashboard used by over 200
                            members, with a tech stack of React, TypeScript, Prisma, PostgresQL, and Express.js.
                        </li>
                        <li>
                            Contributed towards a critcial deployment and notification pipeline, helping secure the ”Excellence in Project
                            Management” award at Formula Hybrid Electric 2024.
                        </li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-teal-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Software Engineering Co-op - Backend
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Flaplive • Boston, Massachusetts
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        09/2023 - 01/2024
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">

                        <li>
                            Accelerated a GPT bot prototype’s performance by 45% by facilitating a migration to a full-stack application.                        </li>
                        <li>
                            Programmed a Node.js backend for efficient validation, alongside a Flask backend implementing a Langchain-based object-oriented setup for input processing, collectively ensuring loose coupling and scalability.
                        </li>
                        <li>
                            Contributed to the backend development of an onboarding portal, improving upon the previous Google Forms system.
                        </li>
                        <li>
                            Programmed a REST API with Express.js, integrating CRUD functionalities, file validation middleware and JSON Web Token authentication to strengthen security and data management with a MySQL database.
                        </li>
                        <li>
                            Leveraged AWS S3 for file storage and transfer and AWS CodeCommit and CodePipeline for streamlined version control and CICD, optimizing development workflow.
                        </li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-teal-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Software Engineering Intern - GPT R&D
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Acuvate Software Ltd. • Hyderabad, India
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        05/2023 - 08/2023
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">
                        <li>
                            Crafted a Project Review Document entrusted by the COO, highlighting GPT/DALLE-powered ad creative generation for an AI convention showcase.                        </li>
                        <li>
                            Propelled crucial engagement with General Electric by developing a multi-source query chatbot PoC, employing Azure OpenAI/Langchain.
                        </li>
                        <li>
                            Designed a Langchain chatbot adeptly addressing queries across 50+ MSSQL tables and 1000+ pages of docs.
                        </li>
                        <li>
                            Collaborated with the DevOps team to Dockerize and deploy the chatbot on Azure App Service, enhancing functionality and accessibility.
                        </li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-teal-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Computer Science Teaching Assistant (TA)
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Khoury College of Computer Sciences • Boston, Massachusetts
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        09/2022 - 04/2023
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">
                        <li>
                            Worked for classes including Algorithms and Data Structures, and Discrete Structures.
                        </li>
                        <li>
                            Conducted Office Hours to assist 400+ students with assignments and course concepts such as Dynamic Programming, Graphs, and Flow Networks.

                        </li>
                        <li>
                            Reviewed and graded weekly assignments, proctored examinations to assist instructors with running the course.
                        </li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-sky-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Trainee, Intensive Cloud Bootcamp
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        The Cloud Bootcamp • San Francisco, California
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        01/2023 - 2/2023
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">
                        <li>
                            Successfully finished a 14-day remote training program in cloud computing, covering essential concepts and principles of the field.</li>
                        <li>
                            Participated in a sample multi-cloud migration project, which involved the transition of a SQL database and legacy on-premises file storage to cloud infrastructure.</li>
                        <li>
                            Acquired hands-on expertise in cutting-edge cloud technologies such as Docker for containerization, Terraform for infrastructure as code, Amazon S3 for object storage, Google Kubernetes Engine for container orchestration, and Google Cloud SQL for managed relational database services.</li>

                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-sky-600 text-white py-6 px-3'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Software Developer Internship
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Global Cybersecurity Forum • Hyderabad, India
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        04/2020 - 06/2020
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">
                        <li>
                            Observed the day-to-day operations of a software company by shadowing professionals at various levels, gaining insight into the workings of the organization.                        </li>
                        <li>
                            Assisted the project manager by preparing and organizing meeting agendas and taking accurate minutes during sprint meetings.                        </li>
                        <li>
                            Demonstrated technical proficiency by debugging complex Python scripts and creating documentation for a password strength testing program. Contributed to the project's success by ensuring the scripts' accuracy and efficiency.                        </li>
                    </ul>
                </div>
                <div className='flex flex-col h-full bg-gradient-to-b from-zinc-600 to-slate-600 mt-6 shadow-lg shadow-sky-600 text-white py-6 px-3 mb-10'>
                    <h3 className='text-2xl md:text-3xl text-start py-2'>
                        Founder and President, Coding Club
                    </h3>
                    <p className='text-lg md:text-xl text-start'>
                        Aga Khan Academy • Hyderabad, India
                    </p>
                    <p className='text-lg md:text-xl text-start'>
                        9/2019 - 02/2021
                    </p>
                    <ul class="text-xl md:text-2xl space-y-1 text-start list-disc list-inside">
                        <li>
                            Founded a Coding Club in junior year of highschool and taught multiple programming languages including Java, Python, and C++ to students of
                            various classes due to a lack of CS faculty.
                        </li>
                        <li>
                            Worked with departments across my highschool, including marketing and admissions, in developing new projects
                            and securing internships for juniors and seniors.
                        </li>

                    </ul>
                </div>

            </div>
        </div>
    )
}

export default Experience