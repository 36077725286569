import React from 'react'
import gump from "../images/gump.jpg"
import SmartStock from "../images/SmartStock.png"
import SentimentAnalysis from "../images/sentiment-analysis.jpg"
import react from "../images/logo192.png"
import { FaGithub } from "react-icons/fa";
import firebase from "../images/firebase-logo.png"
import dialogflow from "../images/dialogflow-logo.png"
import cloudfunction from "../images/cloud-functions-logo.png"
import zero from "../images/zero-logo.png"
import python from "../images/python_logo.png"
import flask from "../images/flask_logo.png"
import sql from "../images/sql_logo.png"
import typescript from "../images/typescript_logo.png"
import nextjs from "../images/nextjs_logo.png"
import java from "../images/java_logo2.png"
import tailwind from "../images/tailwind_logo.png"
import cloudfront from "../images/cloudfront.jpg"
import s3 from "../images/s3.png"
import traffic from "../images/traffic.jpg"
import tf from "../images/tf.png"
import logo from "../images/logo.jpg"
import logo1 from "../images/logo1.png"
import swift from "../images/swift_logo.png"
import swiftui from "../images/swiftui_logo.png"
import scrumpad from "../images/scrumpad.jpg"
import opencv from "../images/opencv.png"
import mp from "../images/mediapipe.png"

function Projects() {
    return (
        <div className='h-screen w-screen overflow-auto bg-gradient-to-b from-zinc-400 to-slate-300'>
            <div className='max-w-screen-lg mx-auto flex flex-col px-4'>
                <div className='flex flex-col mt-24 items-center justify-evenly'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black mb-24'>
                        Projects
                    </h2>
                </div>
                <div className='flex flex-col items-center py-6'>
                    {/*FormFusion*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={logo1} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                FormFusion</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Form-Fusion'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={mp} alt='mediapipe' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={opencv} alt='opencv' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                                <img src={python} alt='python' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                                <img src={flask} alt='flask' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={s3} alt='s3' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Developed a web-based application that enables the analysis of workout routines through recorded videos.
                                </li>
                                <li>
                                    Integrated a state-of-the-art machine learning API utilizing OpenCV and MediaPipe, capable of tracking 33 landmark points to provide real-time feedback.
                                </li>
                                <li>
                                    Implemented a computational process to calculate joint angles and muscle compression/relaxation levels, thereby annotating the videos for more in-depth review and analysis.
                                </li>
                                Utilized boto3, the Amazon Web Services (AWS) Python Software Development Kit (SDK), to access S3 buckets and store the analyzed video data.

                            </ul>
                        </div>
                    </div>
                    {/*Zero Chatbot*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={zero} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Zero</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Zero-chatbot'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={firebase} alt='firebase' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                                <img src={dialogflow} alt='dialogflow' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                                <img src={cloudfunction} alt='cloudfunction' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Trained a chatbot that responds to frequently asked questions about my resume.
                                </li>
                                <li>
                                    Utilized Google's Dialogflow Natural Language Understanding Platform that scales automatically with Google Cloud App Engine.
                                </li>
                                <li>
                                    Programmed a Cloud function to store the questions that the bot can't answer to a Firebase realtime database.
                                </li>
                                <li>
                                    Elevated functionality by integrating GPT and Langchain, enhancing generative capabilities and session management for comprehensive user interactions.
                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*Sentiment Analysis*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={SentimentAnalysis} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Sentiment Analysis</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Sentiment-Analysis'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={typescript} alt='typescript' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={nextjs} alt='nextjs' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Built a web application to identify sentiments and emotions in written text or a URL.
                                </li>
                                <li>
                                    Programmed using ReactJS and ExpressJS as part of IBM's Developing Cloud Applications with Node.js and React course.
                                </li>
                                <li>
                                    Modified into a NextJS app with Typescript and TailwindCSS as an introduction to those technologies.
                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*Smart Stock*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-contain w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={SmartStock} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Smart Stock</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/SmartStock'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={python} alt='python' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={flask} alt='flask' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                                <img src={sql} alt='sql' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Constructed a web application showcasing a proof-of-concept for a stock-trading and portfolio manager.
                                </li>
                                <li>
                                    Worked with a peer as part of a pair programming project; was responsible for creating a MySQL database and running it in a Docker container.
                                </li>
                                <li>
                                    Handled API endpoints using Flask and set up a dynamic frontend using Appsmith.
                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*Scrumpad*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-contain w-full rounded-t-lg h-auto md:h-auto md:w-48 md:rounded-lg md:ml-2" src={scrumpad} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Scrumpad</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Scrumdinger'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={swift} alt='swift' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={swiftui} alt='swiftui' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Built an iOS application to manage daily scrum meetings as part of iOS App Dev Tutorials.
                                </li>
                                <li>
                                    Keeps track of the speaker and speaking time, and generates a transcript after the meeting is done.
                                </li>
                                <li>
                                    Currently adding a feature to add notes to each meeting.
                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*Image Processor*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={gump} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Image Processor</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Image-Processor'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={java} alt='java' className='object-contain h-10 w-10 hover:scale-125 duration-150' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Designed and coded a GUI-based Image processor that works with every image format.
                                </li>
                                <li>
                                    Followed test driven development principles and utilized the MVC architecture with loose coupling to add multiple views.

                                </li>
                                <li>
                                    Supports a variety of popular options like blur, sharpen, sepia, greyscale etc.

                                </li>

                            </ul>
                        </div>
                    </div>
                    {/*This Website*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={logo} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                This Website</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/portfolio'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={react} alt='react' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={tailwind} alt='tailwind' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                                <img src={cloudfront} alt='cloudfront' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                                <img src={s3} alt='s3' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Designed a web portfoliio to showcase my personal projects and experiences.
                                </li>
                                <li>
                                    Built using ReactJS and served as my introduction to TailwindCSS.
                                </li>
                                <li>
                                    Deployed with a custom domain using Route 53 and AWS Cloudfront Content Delivery Network.                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*Traffic-sign-AI*/}
                    <div class="flex flex-col py-4 mb-5 items-center bg-gradient-to-b from-gray-900 to-slate-800 border rounded-lg shadow-md md:flex-row md:max-w-xl">
                        <img class="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-lg md:ml-2" src={traffic} alt="" />

                        <div class="flex flex-col justify-between p-4 leading-normal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-white">
                                Traffic-sign-AI</h5>
                            <div className='flex flex-row'>
                                <a
                                    href='https://github.com/AdityaBoddepalli/Traffic-sign-AI'
                                    className='w-fit text-white hover:text-gray-400 hover:scale-125 duration-150'
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaGithub
                                        size={40} />
                                </a>
                                <img src={python} alt='python' className='object-contain h-10 w-10 hover:scale-125 duration-150 mx-2' />
                                <img src={tf} alt='tf' className='object-contain h-10 w-10 hover:scale-125 duration-150 mr-2' />
                            </div>
                            <ul class="text-l md:text-xl space-y-1 text-start list-disc list-inside mb-3 font-normal text-gray-200">
                                <li>
                                    Programmed an AI using TensorFlow to build a neural network to classify traffic lights from an image of those signs.
                                </li>
                                <li>
                                    Trained 2 convoluted layers with 50 filters to achieve an accuracy of 96%.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects