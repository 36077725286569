import React from "react";

function ErrorPage() {
    return (
        <>
            <div name="screen" className='h-screen w-full bg-gradient-to-b from-black to-gray-800'>
                <div name="imgAndIntro" className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row'>
                    <div name="intro" className='flex flex-col justify-center h-full'>
                        <h2 className='text-4xl md:text-7xl font-bold text-white'>
                            Page not found ☹️.
                        </h2>
                        <p className='text-2xl md:text-3xl text-gray-500 py-4 max-w-md'>
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage;