import React from 'react'

function Contact() {
    return (
        <div className='h-screen w-screen overflow-auto bg-gradient-to-b from-zinc-400 to-slate-300'>
            <div className='max-w-screen-lg mx-auto flex flex-col px-4'>
                <div className='flex flex-col h-full mt-24 items-center justify-evenly py-6'>
                    <h2 className='text-4xl md:text-7xl font-bold text-black'>
                        Contact Me
                    </h2>
                </div>
                <div className='flex justify-center items-center'>
                    <form
                        action="https://getform.io/f/9fc82292-5225-4010-b963-2d45248c4021"
                        method='POST'
                        className='flex flex-col w-full md:w-1/2 text-black'>
                        <input
                            type="text"
                            name="name"
                            placeholder='Enter you name'
                            className='p-3 bg-white border-2 border-slate-600 rounded-md text-black focus:outline-none'
                        />
                        <input
                            type="text"
                            name="email"
                            placeholder='Enter you email'
                            className='my-4 p-3 bg-white border-2 border-slate-600 rounded-md text-black focus:outline-none'
                        />
                        <textarea name='message' placeholder='Enter your message' rows={10}
                            className='p-3 bg-white border-2 border-slate-600 rounded-md text-black focus:outline-double'></textarea>

                        <button className='text-black bg-gradient-to-b from-cyan-500 to-sky-500 px-6 py-3 my-8 mx-auto items-center rounded-md hover:scale-110 duration-300'>Send</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default Contact