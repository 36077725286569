import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter as Router, RouterProvider } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Skills from './components/Skills';
import ErrorPage from './components/fourOfour';
import ComingSoon from './components/ComingSoon';
import Zero from './components/Zero';



const router = Router([
  {
    path: "/",
    element: <App curr={<Home />} />,
    errorElement: <App curr={<ErrorPage />} />
  },
  {
    path: "/projects",
    element: <App curr={<Projects />} />
  },
  {
    path: "/experience",
    element: <App curr={<Experience />} />
  },
  {
    path: "/contactme",
    element: <App curr={<Contact />} />
  },
  {
    path: "/skills",
    element: <App curr={<Skills />} />
  },
  {
    path: "/coming-soon",
    element: <App curr={<ComingSoon />} />
  },
  {
    path: "/zero",
    element: <App curr={<Zero />} />
  },
])




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
