import Navbar from "./components/Navbar";

function App({curr}) {
  return (
    <div>
    <Navbar />
    <main>{curr}</main>
    </div>
  );
}

export default App;
