import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

function Socials() {

    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/in/aditya-boddepalli',
            style: 'rounded-tr-md'

        },
        {
            id: 2,
            child: (
                <>
                    Github <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/AdityaBoddepalli',
        },
        {
            id: 3,
            child: (
                <>
                    Email <HiOutlineMail size={30} />
                </>
            ),
            href: 'mailto:adityaboddepalli@outlook.com',
        },
        {
            id: 4,
            child: (
                <>
                    Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: '/resume.pdf',
            style: 'rounded-br-md',
            download: false,
        }
    ]

    return (
        <div className="flex flex-col top-[40%] md:top-[75%] left-0 fixed lg:top-[35%]">
            <ul>
                {links.map(link => (
                <li key={link.id} className={`flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-gray-700 ${link.style}`}>

                    <a 
                    href={link.href}
                    className = "flex justify-between items-center w-full text-white"
                    download={link.download}
                    target="_blank"
                    rel="noreferrer"
                    >
                        {link.child}
                    </a>

                </li>
        ))}
            </ul>

        </div>
    )
}

export default Socials;